<template>
  <div id="room-form-page">
    <section class="create-room">
      <div class="container">
        <form @submit.prevent="create">
          <div class="box-item-cr mb40">
            <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseCase" aria-expanded="false" aria-controls="collapseCase">
              Case Builder
            </button>
            <div class="collapse" id="collapseCase">
              <div class="box-create">
                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="create-form">
                      <label class="label-form">Category Topic</label>
                      <select class="form-select" v-model="form.topic" :class="{ 'is-invalid': $v.form.topic.$error, black: form.topic !== null }">
                        <option :value="null">Category Topic</option>
                        <option v-for="(topic, index) in topics" :key="index" :value="topic.id">{{ topic.name }}</option>
                      </select>
                      <div v-if="$v.form.topic.$error" class="invalid-feedback">
                        <span v-if="!$v.form.topic.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="create-form">
                      <label class="label-form">Committee</label>
                      <select class="form-select" v-model="form.committee" :class="{ 'is-invalid': $v.form.committee.$error, black: form.committee !== null }">
                        <option :value="null">Committee</option>
                        <option v-for="(committee, index) in committees" :key="index" :value="committee.id">{{ committee.name }}</option>
                      </select>
                      <div v-if="$v.form.committee.$error" class="invalid-feedback">
                        <span v-if="!$v.form.committee.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="create-form">
                      <label class="label-form">Case Region</label>
                      <select class="form-select" v-model="form.region" :class="{ 'is-invalid': $v.form.region.$error, black: form.region !== null }">
                        <option :value="null">Case Region</option>
                        <option v-for="(region, index) in regions" :key="index" :value="region.id">{{ region.name }}</option>
                      </select>
                      <div v-if="$v.form.region.$error" class="invalid-feedback">
                        <span v-if="!$v.form.region.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="create-form" :class="[$v.form.registration_start_date.$error ? 'date-error' : 'date']">
                          <label class="label-form" :class="{ 'is-invalid': $v.form.registration_start_date.$error }">Registration Start Date</label>
                          <date-picker v-model="form.registration_start_date" format="DD-MM-YYYY" :clearable="false" placeholder="Registration Start Date" inputClass="form-control datepicker fordate">
                            <i slot="icon-calendar"></i>
                          </date-picker>
                          <div v-if="$v.form.registration_start_date.$error" class="invalid-feedback">
                            <span v-if="!$v.form.registration_start_date.required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="create-form" :class="[$v.form.registration_end_date.$error ? 'date-error' : 'date']">
                          <label class="label-form" :class="{ 'is-invalid': $v.form.registration_end_date.$error }">Registration End Date</label>
                          <date-picker v-model="form.registration_end_date" format="DD-MM-YYYY" :clearable="false" :disabled-date="notBefore" placeholder="Registration End Date" inputClass="form-control datepicker fordate">
                            <i slot="icon-calendar"></i>
                          </date-picker>
                          <div v-if="$v.form.registration_end_date.$error" class="invalid-feedback">
                            <span v-if="!$v.form.registration_end_date.required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-6">
                    <div class="create-form">
                      <label class="label-form">Subject Topic</label>
                      <input type="text" class="form-control" v-model="form.subject" placeholder="Subject Topic" :class="{ 'is-invalid': $v.form.subject.$error }">
                      <div v-if="$v.form.subject.$error" class="invalid-feedback">
                        <span v-if="!$v.form.subject.$required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-lg-6">
                    <div class="create-form" :class="[$v.form.simulation_date.$error ? 'date-error' : 'date']">
                      <label class="label-form" :class="{ 'is-invalid': $v.form.simulation_date.$error }">Simulation Date</label>
                      <date-picker v-model="form.simulation_date" format="DD-MM-YYYY" :clearable="false" placeholder="Simulation Date" inputClass="form-control datepicker fordate">
                        <i slot="icon-calendar"></i>
                      </date-picker>
                      <div v-if="$v.form.simulation_date.$error" class="invalid-feedback">
                        <span v-if="!$v.form.simulation_date.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-lg-12">
                    <div class="create-form">
                      <label class="label-form" :class="{ 'is-invalid': $v.form.overview.$error }">Case Overview</label>
                      <vue-editor v-model="form.overview" placeholder="Case Overview"></vue-editor>
                      <div v-if="$v.form.overview.$error" class="invalid-feedback">
                        <span v-if="!$v.form.overview.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-lg-12">
                    <div class="box-upload">
                      <label class="label-form">Upload Banner</label>
                      <div class="drop-zone" @click="$refs.inputBanner.click()">
                        <span class="drop-zone__prompt" v-if="form.upload.isEmpty">
                          <img src="/img/icn-upload.svg" class="img-fluid"><br>
                          Click this button to upload file
                        </span>
                        <div class="drop-zone__thumb" :data-label="form.upload.filename" v-if="!form.upload.isEmpty">
                          <img :src="form.upload.fileUrl" class="img-fluid"><br>
                        </div>
                        <input type="file" id="input-banner" ref="inputBanner" name="myFile" accept=".jpg, .jpeg, .png, .gif" class="drop-zone__input" @change="preview">
                      </div>
                      <div class="d-flex align-items-center justify-content-between mt30">
                        <div class="placeholder">&nbsp;</div>
                        <div class="img-requirment">
                          *Allowed JPG, JPEG, GIF or PNG. Max size of 4 MB
                        </div>
                        <div class="for-btn-reset">
                          <button type="button" class="btn btn-delete w-100" :disabled="form.upload.isEmpty" @click="clearBanner">Reset</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-lg-12">
                    <div class="create-form">
                      <label class="label-form" :class="{ 'is-invalid': $v.form.situation.$error }">The Situation</label>
                      <vue-editor v-model="form.situation" placeholder="The Situation"></vue-editor>
                      <div v-if="$v.form.situation.$error" class="invalid-feedback">
                        <span v-if="!$v.form.situation.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-lg-12">
                    <div class="create-form">
                      <label class="label-form" :class="{ 'is-invalid': $v.form.model.$error }">Model Guide</label>
                      <vue-editor v-model="form.model" placeholder="Model Guide"></vue-editor>
                      <div v-if="$v.form.model.$error" class="invalid-feedback">
                        <span v-if="!$v.form.model.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-6">
                    <div class="create-form">
                      <label class="label-form" :class="{ 'is-invalid': $v.form.link.$error }">Link URL Overview</label>
                      <input type="text" class="form-control" v-model="form.link" placeholder="Link Youtube Video">
                      <div v-if="$v.form.link.$error" class="invalid-feedback">
                        <span v-if="!$v.form.link.required">This value is required.</span>
                        <span v-if="!$v.form.link.url">This value must URL format.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-lg-6">
                    <div class="create-form">
                      <label class="label-form" :class="{ 'is-invalid': $v.form.link_embed.$error }">Link URL Model Guide</label>
                      <input type="text" class="form-control" v-model="form.link_embed" placeholder="Link Youtube Video">
                      <div v-if="$v.form.link_embed.$error" class="invalid-feedback">
                        <span v-if="!$v.form.link_embed.required">This value is required.</span>
                        <span v-if="!$v.form.link_embed.url">This value must URL format.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-lg-12">
                    <div class="create-form">
                      <label class="label-form" :class="{ 'is-invalid': $v.form.issue.$error }">The Issue</label>
                      <vue-editor v-model="form.issue" placeholder="The Issue"></vue-editor>
                      <div v-if="$v.form.issue.$error" class="invalid-feedback">
                        <span v-if="!$v.form.issue.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <p class="subtit">Add Country and Role</p>
                <div class="row" v-if="$route.name == 'RoomCreate'">
                  <div class="col-sm-6 col-lg-6 pt-3">
                    <div class="create-form">
                        <label class="label-form">Select Method</label>
                        <select class="form-select black" v-model="uploadMethod">
                          <option value="upload">Upload CSV File</option>
                          <option value="input">Input Subject Manually</option>
                        </select>
                      </div>
                  </div>
                  <div class="col-sm-6 col-lg-6" v-if="uploadMethod === 'upload'">
                    <p class="orange" style="font-size: 12px; margin-bottom: 0px">
                      Please download the CSV template below, before uploading the file
                    </p>
                    <a href="https://dip-api.demoapp.xyz/storage/template01.xlsx" class="btn btn-orange w-20">
                      Export
                    </a>
                  </div>
                </div>
                <template v-if="uploadMethod === 'input'">
                  <template v-for="(participant, index) in form.participants">
                    <div :key="index">
                      <div class="row">
                        <div class="col-sm-6 col-lg-6">
                          <div class="create-form">
                            <label class="label-form">Type</label>
                            <select class="form-select" v-model="participant.type" :class="{ 'is-invalid': $v.form.participants.$each.$iter[index].$error, black: participant.type !== null }">
                              <option :value="null">Type</option>
                              <option v-for="(type, i) in types" :key="i" :value="type.id">{{ type.name }}</option>
                            </select>
                            <div v-if="$v.form.participants.$each.$iter[index].$error" class="invalid-feedback">
                              <span v-if="!$v.form.participants.$each.$iter[index].type.required">This value is required.</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-lg-6">
                          <div class="create-form">
                            <div class="box-add-form">
                              <div class="form-part">
                                <label class="label-form" :class="{ 'is-invalid': $v.form.participants.$each.$iter[index].$error }">Country / NGO</label>
                                <template v-if="participant.type == 1">
                                  <select class="form-select" v-model="participant.name" :class="{ 'is-invalid': $v.form.participants.$each.$iter[index].$error, black: participant.name !== null }">
                                    <option :value="null">Country</option>
                                    <option v-for="(country, i) in countries" :key="i" :value="country.name">{{ country.name }}</option>
                                  </select>
                                </template>
                                <template v-else-if="participant.type == 2">
                                  <input type="text" class="form-control" placeholder="NGO" v-model="participant.name">
                                </template>
                                <template v-else>
                                  <input type="text" class="form-control" placeholder="Country / NGO" v-model="participant.name" disabled>
                                </template>
                                <div v-if="$v.form.participants.$each.$iter[index].$error" class="invalid-feedback">
                                  <span v-if="!$v.form.participants.$each.$iter[index].name.required">This value is required.</span>
                                </div>
                              </div>
                              <div class="button-add-form">
                                <button v-if="index == 0" type="button" :id="`add-participant-${index}`" class="btn btn-add-form" @click="addParticipant"><span class="icon-ico-add"></span></button>
                                <button v-if="index > 0" type="button" :id="`remove-participant-${index}`" class="btn btn-add-form" @click="removeParticipant(index)"><span class="icon-ico-delete"></span></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-lg-12">
                          <div class="create-form">
                            <label class="label-form" :class="{ 'is-invalid': $v.form.participants.$each.$iter[index].$error }">Role</label>
                            <vue-editor v-model="participant.role" placeholder="Role"></vue-editor>
                            <div v-if="$v.form.participants.$each.$iter[index].$error" class="invalid-feedback">
                              <span v-if="!$v.form.participants.$each.$iter[index].role.required">This value is required.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="uploadMethod === 'upload'">
                  <div class="row">
                    <div class="col-sm-12 col-lg-12">
                      <div class="box-upload csv">
                        <label class="label-form">Upload File</label>
                        <div class="drop-zone" @click="$refs.inputCSV.click()">
                          <span class="drop-zone__prompt" v-if="form.csv.isEmpty">
                            <img src="/img/upload-file.png" class="img-fluid w-25"><br>
                            Select a CSV File to Upload
                            <br>
                            <button type="button" class="btn btn-orange w-50 mt-3">Choose File</button>
                          </span>
                          <!-- <div class="drop-zone__thumb" :data-label="form.csv.filename" v-if="!form.csv.isEmpty">
                            <img src="/img/upload-file.png" class="img-fluid w-25"><br>
                          </div> -->
                          <div class="row" v-if="!form.csv.isEmpty">
                            <div class="col-sm-3">
                              <img src="/img/upload-file.png" class="img-fluid w-25">
                            </div>
                            <div class="col-sm-4 pt-2">
                              {{ form.csv.filename }}
                            </div>
                            <div class="col-sm-3 pt-2">
                              {{ form.csv.size }} KB
                            </div>
                            <div class="col-sm-2">
                              <button type="button" class="btn btn-orange" @click="clearFileCSV">Reset</button>
                            </div>
                          </div>
                          <input type="file" id="input-csv" ref="inputCSV" name="myFile" accept=".csv, .xls, .xlsx" class="drop-zone__input" @change="previewCSV">
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="box-item-cr mb40">
            <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseConference" aria-expanded="false" aria-controls="collapseConference">
              Conference Settings
            </button>
            <div class="collapse" id="collapseConference">
              <div class="box-create">
                <div class="row">
                  <div class="col-sm-6 col-lg-6">
                    <div class="create-form">
                      <p-radio class="p-default p-curve" name="color" color="primary-o" value="zoom" v-model="form.conference">Zoom</p-radio>
                    </div>
                  </div>
                  <div class="col-sm-6 col-lg-6">
                    <div class="create-form">
                      <p-radio class="p-default p-curve" name="color" color="primary-o" value="google_meet" v-model="form.conference">Google meet</p-radio>
                    </div>
                  </div>
                </div>
                <template v-if="form.conference == 'zoom'">
                  <div class="row">
                    <div class="col-sm-12 col-lg-12">
                      <div class="create-form">
                        <label class="label-form">Link Zoom</label>
                        <input type="text" class="form-control" placeholder="Link Zoom" v-model="form.zoom" :class="{ 'is-invalid': $v.form.zoom.$error }" />
                        <div v-if="$v.form.zoom.$error" class="invalid-feedback">
                          <span v-if="!$v.form.zoom.required">This value is required.</span>
                          <span v-if="!$v.form.zoom.url">This value must URL format.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-for="(meeting, index) in form.meetings">
                    <div class="row" :key="index">
                      <div class="col-sm-6 col-lg-6">
                        <div class="create-form">
                          <label class="label-form">Meeting ID</label>
                          <input type="text" class="form-control" placeholder="Meeting ID" v-model="meeting.id" :class="{ 'is-invalid': $v.form.meetings.$each.$iter[index].$error }">
                          <div v-if="$v.form.meetings.$each.$iter[index].$error" class="invalid-feedback">
                            <span v-if="!$v.form.meetings.$each.$iter[index].id.required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-6">
                        <div class="create-form">
                          <!-- <div class="box-add-form">
                            <div class="form-part"> -->
                              <label class="label-form">Password</label>
                              <input type="text" class="form-control" placeholder="Password" v-model="meeting.password" :class="{ 'is-invalid': $v.form.meetings.$each.$iter[index].$error }">
                              <div v-if="$v.form.meetings.$each.$iter[index].$error" class="invalid-feedback">
                                <span v-if="!$v.form.meetings.$each.$iter[index].password.required">This value is required.</span>
                              <!-- </div>
                            </div> -->
                            <!-- <div class="button-add-form">
                              <button type="button" v-if="index == 0" :id="`add-meeting-${index}`" class="btn btn-add-form" @click="addMeeting"><span class="icon-ico-add"></span></button>
                              <button type="button" v-if="index > 0" :id="`remove-meeting-${index}`" class="btn btn-add-form" @click="removeMeeting(index)"><span class="icon-ico-delete"></span></button>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="row">
                    <div class="col-sm-12 col-lg-12">
                      <div class="create-form">
                        <label class="label-form">Link Google Meet</label>
                        <input type="text" class="form-control" placeholder="Link Google Meet" v-model="form.google_meet" :class="{ 'is-invalid': $v.form.google_meet.$error }" />
                        <div v-if="$v.form.google_meet.$error" class="invalid-feedback">
                          <span v-if="!$v.form.google_meet.required">This value is required.</span>
                          <span v-if="!$v.form.google_meet.url">This value must URL format.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="for-participant" :class="{ 'is-invalid': $v.form.total_participant.$error }">
                      <div class="set-text">Set Participant</div>
                      <div class="p-qount">
                        <span class="input-group-btn">
                          <button @click="form.total_participant--" type="button" class="btn btn-qount">
                            <span class="icon-ico-minus"></span>
                          </button>
                        </span>
                        <input type="text" id="quantity" v-model="form.total_participant" name="quantity" class="form-control input-number">
                        <span class="input-group-btn">
                          <button @click="form.total_participant++" type="button" class="btn btn-qount">
                            <span class="icon-ico-plus"></span>
                          </button>
                        </span>
                      </div>
                    </div>
                    <div v-if="$v.form.total_participant.$error" class="invalid-feedback">
                      <span v-if="!$v.form.total_participant.required">This value is required.</span>
                      <span v-if="!$v.form.total_participant.minValue">This value have at least {{$v.form.total_participant.$params.minValue.min}} participants.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="for-btn-save">
            <div class="btn-save-box">
              <button type="submit" class="btn btn-orange w-100" id="save-form">Save Changes</button>
            </div>
            <div class="btn-delete-box">
              <router-link to="/room" class="btn btn-delete w-100" id="reset-form">Cancel</router-link>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  required,
  minValue,
  requiredIf
} from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

export default {
  name: "RoomForm",
  components: { VueEditor },
  data() {
    return {
      regions: [],
      committees: [],
      topics: [],
      countries: [],
      types: [
        {
          id: 1,
          name: 'Country'
        },
        {
          id: 2,
          name: 'NGO'
        }
      ],
      form: {
        topic: null,
        committee: null,
        region: null,
        registration_start_date: null,
        registration_end_date: null,
        subject: null,
        simulation_date: null,
        overview: null,
        banners: [],
        situation: null,
        model: null,
        link: null,
        link_embed: null,
        issue: null,
        participants: [
          {
            type: null,
            name: null,
            role: null
          }
        ],
        conference: "zoom",
        meetings: [
          {
            id: null,
            password: null
          }
        ],
        total_participant: 1,
        upload: {
          isEmpty: true,
          filename: null,
          file: null,
          fileUrl: null
        },
        csv: {
          isEmpty: true,
          filename: null,
          file: null,
          fileUrl: null,
          size: null
        }
      },
      uploadMethod: 'input'
    }
  },
  validations() {
    return {
      form: {
        topic: { required },
        committee: { required },
        region: { required },
        registration_start_date: { required },
        registration_end_date: { required },
        subject: { required },
        simulation_date: { required },
        overview: { required },
        situation: { required },
        model: { required },
        link: { required },
        link_embed: { required },
        issue: { required },
        participants: {
          $each: {
            type: { 
              required: requiredIf(() => {  
                return this.uploadMethod == 'input' 
              }) 
            },
            name: {
              required: requiredIf(() => {  
                return this.uploadMethod == 'input' 
              }) 
            },
            role: {
              required: requiredIf(() => {  
                return this.uploadMethod == 'input' 
              }) 
            }
          }
        },
        meetings: {
          $each: {
            id: { 
              required: requiredIf(() => {  
                return this.form.conference == 'zoom' 
              }) 
            },
            password: { 
              required: requiredIf(() => {  
                return this.form.conference == 'zoom' 
              }) 
            }
          }
        },
        zoom: { 
          required: requiredIf(() => {  
            return this.form.conference == 'zoom' 
          }) 
        },
        google_meet: { 
          required: requiredIf(() => {  
            return this.form.conference == 'google_meet' 
          }) 
        },
        total_participant: {
          required,
          minValue: minValue(1)
        }
      }
    }
  },
  mounted() {
    this.fetchRegions();
    this.fetchCommittees();
    this.fetchTopics();
    this.fetchCountries();

    if (this.$route.name == 'RoomEdit') {
      this.getRoomDetail() 
    }
  },
  methods: {
    fetchRegions() {
      this.$axios.get('/cms/region', {
        params: {
          per_page: 100,
          sortBy: 'name',
          sortType: 'asc'
        }
      })
        .then(response => {
          this.regions = response.data.data.data
        })
    },
    fetchCommittees() {
      this.$axios.get('/cms/committee', {
        params: {
          per_page: 100,
          sortBy: 'name',
          sortType: 'asc'
        }
      })
        .then(response => {
          this.committees = response.data.data.data
        })
    },
    fetchTopics() {
      this.$axios.get('/cms/category_topic', {
        params: {
          per_page: 100,
          sortBy: 'name',
          sortType: 'asc'
        }
      })
        .then(response => {
          this.topics = response.data.data.data
        })
    },
    fetchCountries() {
      this.$axios.get('/country_list')
        .then(response => {
          this.countries = response.data.data
        })
    },
    notBefore(date) {
      return date < new Date(this.form.registration_start_date);
    },
    addParticipant() {
      this.form.participants.push({
        type: null,
        name: null,
        role: null
      });
    },
    removeParticipant(index) {
      this.form.participants.splice(index, 1);
    },
    addMeeting() {
      this.form.meetings.push({
        id: null,
        password: null
      });
    },
    removeMeeting(index) {
      this.form.meetings.splice(index, 1);
    },
    preview(event) {
      if(event.target.files[0] !== undefined){
        let extension = event.target.files[0].name.split(".").pop()
        let allowedExtention = ['jpg','jpeg','png','gif']
        if(allowedExtention.includes(extension.toLowerCase())){
          this.form.upload.isEmpty = false
          this.form.upload.filename = event.target.files[0].name
          this.form.upload.file = event.target.files[0]
          this.form.upload.fileUrl = URL.createObjectURL(event.target.files[0]);
        }
      }
    },
    previewCSV(event) {
      if(event.target.files[0] !== undefined){
        console.log(event.target.files[0]);
        let extension = event.target.files[0].name.split(".").pop()
        let allowedExtention = ['csv','xls','xlsx']
        if(allowedExtention.includes(extension.toLowerCase())){
          this.form.csv.isEmpty = false
          this.form.csv.filename = event.target.files[0].name
          this.form.csv.file = event.target.files[0]
          this.form.csv.size = event.target.files[0].size
          this.form.csv.fileUrl = URL.createObjectURL(event.target.files[0]);
        }
      }
    },
    clearBanner() {
      this.form.upload.isEmpty = true
      this.form.upload.filename = null
      this.form.upload.file = null
      this.form.upload.fileUrl = null
      document.getElementById('input-banner').value = null
    },
    clearFileCSV() {
      this.form.csv.isEmpty = true
      this.form.csv.filename = null
      this.form.csv.file = null
      this.form.csv.fileUrl = null
      this.form.csv.size = null
      document.getElementById('input-csv').value = null
    },
    getRoomDetail() {
      this.$axios.get(`/master/room/${this.$route.params.id}`)
        .then(response => {
          this.uploadMethod = 'input'
          const room = response.data.data
          this.form.topic = room.category_topic_id
          this.form.region = room.region_id
          this.form.committee = room.committee_id
          this.form.subject = room.subject_topic
          this.form.simulation_date = new Date(this.formatDate(room.simulation_date))
          this.form.registration_start_date = new Date(this.formatDate(room.registration_start_date))
          this.form.registration_end_date = new Date(this.formatDate(room.registration_end_date))
          this.form.overview = room.case_overview
          this.form.issue = room.the_issue
          this.form.model = room.model_guide
          this.form.situation = room.the_situation
          this.form.link = room.link_url
          this.form.link_embed = room.link_embed_url
          this.form.participants = []
          room.participants.forEach(participant => {
            this.form.participants.push({
              type: participant.type,
              name: participant.name,
              role: participant.role_description,
            })
          })
          this.form.total_participant = room.set_participant
          
          if (room.link_google_meet !== null) {
            this.form.conference = 'google_meet'
            this.form.google_meet = room.link_google_meet
          } else {
            this.form.conference = 'zoom'
            this.form.zoom = room.link_zoom
            this.form.meetings = []
            room.meeting_credentials.forEach(meeting => {
              this.form.meetings.push({
                id: meeting.meeting_id,
                password: meeting.password
              })
            })
          }

          this.form.upload = {
            isEmpty: false,
            filename: room.banner[0],
            file: null,
            fileUrl: room.banner_url[0]
          }
        });
    },
    async uploadBanner() {
      let param = new FormData();
      param.append('file', this.form.upload.file);
      param.append('folder', 'room');
      param.append('quality', '80');

      await this.$axios.post('/upload_image', param)
        .then(response => {
          this.form.banners.push(response.data.filename)
        })
    },
    async create() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // loading overlay
        const loader = this.$loading.show({
          container: null,
          lockScroll: true,
          opacity: 1
        });
        if (this.form.upload.file !== null) {
          // upload banner 
          await this.uploadBanner();
        }
        // submit room data
        let submit = null;
        if (this.$route.name == 'RoomEdit') {
          submit = this.$axios.patch('/master/room', {
            id: this.$route.params.id,
            category_topic_id: this.form.topic,
            committee_id: this.form.committee,
            region_id: this.form.region,
            registration_start_date: this.formatDate(this.form.registration_start_date),
            registration_end_date: this.formatDate(this.form.registration_end_date),
            subject_topic: this.form.subject,
            simulation_date: this.formatDate(this.form.simulation_date),
            case_overview: this.form.overview,
            the_situation: this.form.situation,
            banner: this.form.banners.length > 0 ? this.form.banners : "",
            model_guide: this.form.model,
            link_url: this.form.link,
            link_embed_url: this.form.link_embed,
            the_issue: this.form.issue,
            link_zoom: this.form.zoom,
            link_google_meet: this.form.google_meet,
            set_participant: this.form.total_participant,
            participants: this.form.participants.map(participant => {
              return {
                type: participant.type,
                name: participant.name,
                role_description: participant.role
              }
            }),
            meeting_credentials: this.form.conference == 'google_meet'? [] : this.form.meetings.map(meeting => {
              return {
                meeting_id: meeting.id,
                password: meeting.password
              }
            })
          })
        } else {
          const payload = new FormData();
          payload.append('category_topic_id', this.form.topic)
          payload.append('committee_id', this.form.committee)
          payload.append('region_id', this.form.region)
          payload.append('registration_start_date', this.formatDate(this.form.registration_start_date))
          payload.append('registration_end_date', this.formatDate(this.form.registration_end_date))
          payload.append('subject_topic', this.form.subject)
          payload.append('simulation_date', this.formatDate(this.form.simulation_date))
          payload.append('case_overview', this.form.overview)
          payload.append('the_situation', this.form.situation)
          payload.append('banner', this.form.banners.length > 0 ? this.form.banners : "")
          payload.append('model_guide', this.form.model)
          payload.append('link_url', this.form.link)
          payload.append('link_embed_url', this.form.link_embed)
          payload.append('the_issue', this.form.issue)
          payload.append('set_participant', this.form.total_participant)
          payload.append('link_zoom', this.form.conference == 'zoom' ? this.form.zoom : null)
          payload.append('link_google_meet', this.form.conference == 'google_meet' ? this.form.google_meet : null)
          if (this.form.conference == 'zoom') {
            payload.append('meeting_credentials[0][meeting_id]', this.form.meetings[0].id)
            payload.append('meeting_credentials[0][password]', this.form.meetings[0].password)
          }
          if (this.uploadMethod === 'upload') {
            payload.append('list_participant', this.form.csv.file)
          } else {
            this.form.participants.forEach((participant, index) => {
              payload.append(`participants[${index}][type]`, participant.type)
              payload.append(`participants[${index}][name]`, participant.name)
              payload.append(`participants[${index}][role_description]`, participant.role)
            })
          }


          submit = this.$axios.post('/master/room', payload
            // {
            //   category_topic_id: this.form.topic,
            //   committee_id: this.form.committee,
            //   region_id: this.form.region,
            //   registration_start_date: this.formatDate(this.form.registration_start_date),
            //   registration_end_date: this.formatDate(this.form.registration_end_date),
            //   subject_topic: this.form.subject,
            //   simulation_date: this.formatDate(this.form.simulation_date),
            //   case_overview: this.form.overview,
            //   the_situation: this.form.situation,
            //   banner: this.form.banners.length > 0 ? this.form.banners : "",
            //   model_guide: this.form.model,
            //   link_url: this.form.link,
            //   the_issue: this.form.issue,
            //   set_participant: this.form.total_participant,
            //   participants: this.form.participants.map(participant => {
            //     return {
            //       type: participant.type,
            //       name: participant.name,
            //       role_description: participant.role
            //     }
            //   }),
            //   link_zoom: this.form.conference == 'zoom' ? this.form.zoom : null,
            //   link_google_meet: this.form.conference == 'google_meet' ? this.form.google_meet : null,
            //   meeting_credentials: this.form.conference == 'google_meet'? [] : this.form.meetings.map(meeting => {
            //     return {
            //       meeting_id: meeting.id,
            //       password: meeting.password
            //     }
            //   })
            // }
          )
        }
        
        submit.then(response => {
          loader.hide();
          Swal.fire("Success", response.data.message, "success");
          if (response.data.data.success_all) {
            this.$router.push('/room');
          } else {
            this.$router.push('/room/manage/edit/' + response.data.data.id);
          }
        }).catch(error => {
          loader.hide();
          console.log(error);
        })
      }
    }
  }
}
</script>

<style lang="css" scoped>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.mx-datepicker {
  width: 100% !important;
}
.mx-icon-calendar {
  display: none !important;
}
.drop-zone {
  height: auto !important;
}
.drop-zone__thumb {
  max-width: none !important;
}
.black {
  color: black !important;
}
.create-form.date-error::after{
  font-family: 'icomoon';
  content: "\e906";
  font-size: 20px;
  position: absolute;
  right: 15px;
  bottom: 40px;
  color: #434343;
}
.subtit {
  font-family: "Arial";
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #06337B;
  margin-bottom: 32px;
}

.csv .drop-zone__thumb::after {
  height: 20px !important;
}
</style>
